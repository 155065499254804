body {
  top: 0 !important;
  position: unset !important;
  height: 100% !important;
  width: 100% !important;
}

.del-btn {
	color: red;
	position: absolute;
	margin-top: 7px;
	margin-left: -3px;
}

.wrapMe {
  white-space: break-spaces;
}

.photo-options {
    z-index: 500;
    position: inherit;
    top: 40px;
    left: 7px;
}

.product-color {
    width: 100%;
    height: 33px;
    border: #afa5a5 solid 2px;
    margin-top: 2px;
    cursor: not-allowed;
}

@media (min-width: 992px) {
  .auth-brand {
      width: 77% !important;
  }
}

.updateTextInput {
  background-color: transparent;
  border: 0px solid;
  height: 20px;
  width: 160px;
}
.updateTextInput:focus {
    outline: none;
}

.custom-control {
    padding-left: 0 !important;
    left: 23px !important;
}

.custom-control-input {
    left: -27px !important;
    z-index: 100 !important;
}

.margins-for-dropdown {
  margin-top: 13px;
  margin-left: 23px;
} 

.auth-fluid {
      background-size:100% 100%;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: 100vh;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      background-size: cover;
}

.add-avator {
  position: absolute;
  left: -29px;
  top: 3px;
  font-size: 19px;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
.caps {
  text-transform: capitalize;
}

.dropdown-menu {
  max-height: 346px;
  overflow: scroll;
}

.bootstrap-tagsinput {
  width: 100%;
}

span.light-text {
    font-size: 11px;
    font-weight: 300;
    float: right;
}

// .auth-fluid {
//     position: relative;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     min-height: 100vh;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-align: stretch;
//     -ms-flex-align: stretch;
//     align-items: stretch;
//     background: url(../images/bg-auth.jpg) center;
//     background-size: cover;
// }

.pointer {
  cursor: pointer;
}

legend {
  font-size: 25px;
  border-bottom: 1px solid #eee;
  margin: 25px 0 18px 0
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.editor-toolbar.fullscreen, 
.CodeMirror.cm-s-paper.CodeMirror-wrap.CodeMirror-fullscreen, 
.editor-preview-side.editor-preview-active-side {
    z-index: 5251 !important;
}


.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  // padding: 0 6px;
  // margin: 0;
  width: auto;
  height: 30px;
  // max-width: inherit;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #3d7ac6;
  border: 1px solid #3d7ac6;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.note-editable {
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
    color: none !important;
}

.modal-backdrop.show {
    opacity: 0.1 !important;
}

.dash-item-overlay {
    margin-top: 21px;
}

#mapid { height: 180px; }

.edit-icon i {
  position: absolute;
  top: 66px;
  left: 67px;
  font-size: 21px;
}

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: auto;
    margin-bottom: 20px;
  
    .avatar-edit {
        position: absolute;
        right: 40px;
        z-index: 1;
        top: 90px;
        input {
            display: none;
            + label {
              i {
                position: absolute;
                font-size: 30px;
                left: 0;
                margin-top: -5px;
                color: #cc;
              }
                display: inline-block;
                margin-left: -2px;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: white;
                // border: 1px solid #d2d6de;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;           
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                    line-height: 34px;
                    margin: auto;
                }
            }
        }
    }
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #615a5a;
  border-color: #c6c4c4 transparent #5c79e2 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.setupPages {
  overflow: hidden;
}

img.pointer.margin-bottom-10 {
    max-height: 90px;
}


.background {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1100 !important;
  overflow: hidden;
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 5;
.background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (
    #583C87,
    #E45A84,
    #FFACAC
  );
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      opacity: 0.4;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 20s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 0.1px) rotate(360deg);
  }
}


input[data-switch]+label {
    width: 72px !important;
}

input[data-switch]:checked+label:after {
    left: 50px !important;
}

.type_color {
  width: 15px;
  height: 15px;
  margin: 5px;
  margin-top: 3px;
  border-radius: 20px;
}

table span {
    display: inline-flex;
}

.ember-basic-dropdown-content {
  border: 1px solid #eee;
  transform: perspective(1500px) rotateY(15deg);
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 60px -12px;
  transition: transform 1s ease 0s;
  &:hover {
    transform: perspective(3000px) rotateY(5deg);
  }
}

.table-min-height-350 {
  min-height: 418px;
}

.card-min-height-205 {
  min-height: 205px;
}

#profile-b1 .btn {
  min-width: 125px;
}

.printme {
  display: none;
}
@media print {
  .no-printme  {
    display: none;
  }
  .printme  {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}






