.margin-top-0 {
  margin-top: 0px;
}

.padding-top-0 {
  padding-top: 0px;
}

.margin-left-0 {
  margin-left: 0px;
}

.padding-left-0 {
  padding-left: 0px;
}

.margin-right-0 {
  margin-right: 0px;
}

.padding-right-0 {
  padding-right: 0px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.padding-bottom-0 {
  padding-bottom: 0px;
}

.margin-top-5 {
  margin-top: 5px;
}

.padding-top-5 {
  padding-top: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.padding-top-15 {
  padding-top: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.padding-left-15 {
  padding-left: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.padding-right-15 {
  padding-right: 15px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.padding-right-20 {
  padding-right: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.padding-top-25 {
  padding-top: 25px;
}

.margin-left-25 {
  margin-left: 25px;
}

.padding-left-25 {
  padding-left: 25px;
}

.margin-right-25 {
  margin-right: 25px;
}

.padding-right-25 {
  padding-right: 25px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.margin-left-30 {
  margin-left: 30px;
}

.padding-left-30 {
  padding-left: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.padding-right-30 {
  padding-right: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.padding-top-35 {
  padding-top: 35px;
}

.margin-left-35 {
  margin-left: 35px;
}

.padding-left-35 {
  padding-left: 35px;
}

.margin-right-35 {
  margin-right: 35px;
}

.padding-right-35 {
  padding-right: 35px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.padding-top-40 {
  padding-top: 40px;
}

.margin-left-40 {
  margin-left: 40px;
}

.padding-left-40 {
  padding-left: 40px;
}

.margin-right-40 {
  margin-right: 40px;
}

.padding-right-40 {
  padding-right: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.padding-top-50 {
  padding-top: 50px;
}

.margin-left-50 {
  margin-left: 50px;
}

.padding-left-50 {
  padding-left: 50px;
}

.margin-right-50 {
  margin-right: 50px;
}

.padding-right-50 {
  padding-right: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.padding-top-60 {
  padding-top: 60px;
}

.margin-left-60 {
  margin-left: 60px;
}

.padding-left-60 {
  padding-left: 60px;
}

.margin-right-60 {
  margin-right: 60px;
}

.padding-right-60 {
  padding-right: 60px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.padding-top-70 {
  padding-top: 70px;
}

.margin-left-70 {
  margin-left: 70px;
}

.padding-left-70 {
  padding-left: 70px;
}

.margin-right-70 {
  margin-right: 70px;
}

.padding-right-70 {
  padding-right: 70px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}
